import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import React from 'react';

import Footer from '../components/common/Footer';
import Spacer from '../components/layout/Spacer';

const Contact = () => {
  return (
    <Wrapper
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      exit={{ opacity: 0, y: 50 }}
    >
      <div className="column" id="contact">
        <p className="title">送信完了</p>
      </div>
      <Spacer height={'80px'} />
      <p className="contact_greeting">
        お問い合わせいただき、ありがとうございました。
        <br />
        追ってスタッフより返信させていただきます。
      </p>

      <Spacer height={'200px'} />
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  justify-content: center;
  width: 100%;

  .title {
    padding-top: 68px;
    font-size: 32px;
    line-height: 44px;
  }

  .column {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact_greeting {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 200%;

    color: #000000;
  }

  .contents {
    width: 100%;
    padding: 70px 282px 0px 109px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
`;

export default Contact;
